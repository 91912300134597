import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/hegel/hegel/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "editor-plugins"
    }}>{`Editor Plugins`}</h1>
    <hr></hr>
    <blockquote>
      <p parentName="blockquote">{`Editor plugin still in development`}</p>
    </blockquote>
    <p>{`You can improve your developer experience with Hegel by using editor integrations.
Hegel tries to provide editor support for: Type hints and Error display.`}</p>
    <p>{`Plugins:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#visual-studio-code"
        }}>{`Visual Studio Code`}</a></li>
    </ul>
    <h2 {...{
      "id": "visual-studio-code"
    }}>{`Visual Studio Code`}</h2>
    <p>{`Currently, the extension is still in development. So, you can try it only via debug mode.`}</p>
    <p>{`To run the extension in debug mode you need to:`}</p>
    <ol>
      <li parentName="ol">{`Clone Hegel repo`}</li>
    </ol>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`$ git clone https://github.com/JSMonk/hegel
`}</code></pre>
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">
        <p parentName="li">{`Add `}<inlineCode parentName="p">{`hegel/packages/language-server`}</inlineCode>{` to your VS code workspace. Do not
open `}<inlineCode parentName="p">{`hegel`}</inlineCode>{` or add `}<inlineCode parentName="p">{`hegel`}</inlineCode>{` to your workspace, otherwise "Run and Debug" cannot
find the `}<inlineCode parentName="p">{`.vscode/launch.json`}</inlineCode>{` configuration.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Go to the "Run and Debug" section`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Run "Extension" option`}</p>
      </li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      